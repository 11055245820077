<template>
  <div class="header-mobile">
    <div class="" />
    <div>
      <HeaderLogo />
    </div>
    <div class="flex items-center">
      <LiveChat v-if="$auth.user" class="mr-3" />
      <SectionSidebar
        @navigationClicked="$emit('navigationClicked', $event)"
      />
    </div>
  </div>
</template>

<script>
import SectionSidebar from '@design-system/section/sidebar/sidebar.vue'
import LiveChat from '@design-system/section/sidebar/sidebar-live-chat.vue'
import HeaderLogo from '@design-system/section/header/header-shared/header-logo.vue'
export default {
  name: 'HeaderMobile',
  components: {
    SectionSidebar,
    HeaderLogo,
    LiveChat

  }

}
</script>

<style lang="scss" scoped>
.header-mobile {
  @apply flex lg:hidden justify-between items-center;
}
</style>
