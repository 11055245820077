import {
  STORE_PAGINATION,
  STORE_META,
  STORE_STATISTIC,
  STORE_TESTIMONIAL,
  STORE_BEST_TEACHERS,
  STORE_FAQS,
  STORE_MEGA_MENU,
  STORE_MOBILE_MENU_STATE,
  STORE_INTERNAL_LINKS,
  STORE_SEO_CONTENT,
  STORE_BREADCRUMB,
  STORE_CURRENCY,
  STORE_FOOTER,
  STORE_BLOG_POSTS
} from '../mutation-types'

export default {
  [STORE_META] (state, payload) {
    state.meta = payload
  },
  [STORE_PAGINATION] (state, payload) {
    state.pagination = { ...payload }
  },
  [STORE_STATISTIC] (state, payload) {
    state.statistics = payload
  },
  [STORE_TESTIMONIAL] (state, payload) {
    state.testimonials = payload
  },
  [STORE_BEST_TEACHERS] (state, payload) {
    state.bestTeachers = payload
  },
  [STORE_FAQS] (state, payload) {
    state.faqs = payload
  },
  [STORE_MEGA_MENU] (state, payload) {
    state.megaMenu = payload
  },
  [STORE_MOBILE_MENU_STATE] (state, payload) {
    state.mobileMenuState = payload
  },
  [STORE_INTERNAL_LINKS] (state, payload) {
    state.internalLinks = payload
  },
  [STORE_SEO_CONTENT] (state, payload) {
    state.seoContent = payload
  },
  [STORE_BREADCRUMB] (state, payload) {
    state.breadcrumb = payload
  },
  [STORE_CURRENCY] (state, payload) {
    state.currency = payload
  },
  [STORE_FOOTER] (state, payload) {
    state.footer = payload
  },
  [STORE_BLOG_POSTS] (state, payload) {
    state.blogPosts = payload
  }
}
