<template>
  <div class="space-y-6" @click="itemClicked($event)">
    <span class="flex items-center space-x-2 cursor-pointer">
      <BaseIcon
        name="explicit"
        color="label-text"
        size="md"
      />
      <span class="text__headline-5-xs--regular  text-label-text">{{ getMegaMenu.anchor }}</span>
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MegaMenuMobileNav',
  computed: {
    ...mapGetters('global', ['getMegaMenu'])
  },
  methods: {
    ...mapActions('global', ['changeMobileMenu']),
    itemClicked (e) {
      e.stopPropagation()
      const state = {
        menu: 'megaMenu',
        layout: 'firstLevel',
        title: this.getMegaMenu.anchor
      }
      this.changeMobileMenu(state)
    }
  }
}
</script>

<style scoped>

</style>
