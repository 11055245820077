import { render, staticRenderFns } from "./rectangle-icon.vue?vue&type=template&id=48ad1a94&scoped=true&"
import script from "./rectangle-icon.vue?vue&type=script&lang=js&"
export * from "./rectangle-icon.vue?vue&type=script&lang=js&"
import style0 from "./rectangle-icon.vue?vue&type=style&index=0&id=48ad1a94&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48ad1a94",
  null
  
)

export default component.exports