<template>
  <div class="footer-info">
    <div class="lg-hidden-block mb-4">
      <BaseAppLogo width="126" height="32" type="logo-full" color="white" classes="mx-0" />
    </div>
    <div class="lg-block-hidden mb-12">
      <BaseAppLogo width="250" height="56" type="logo-full" color="white" classes="mx-0" />
    </div>
    <div v-for="(item,index) in infos" :key="index" class="footer-info__row">
      <span>{{ item.label }}</span>
      <span>{{ item.value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterAppInfo',
  props: {
    infos: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-info {
  @apply w-fit;
  &__row {
    @apply text-white text__body-2-xs--regular lg:text__headline-5-md--regular mt-4 lg:first:mt-5;
    span:first-of-type {
      @apply mr-2 lg:mr-4 text-primary-100;
    }
  }
}
</style>
