<template>
  <footer class="footer" :class="{'footer--fixed-button': pageHasFixedButton}">
    <methodPayment />
    <div class="footer-main">
      <div class="footer-main__container">
        <div class="lg:w-1/2">
          <div class="footer-main__navigations">
            <FooterNavigations :navigation-list="navigationList" />
          </div>

          <div class="footer-main__courses">
            <FooterCourses v-if="courses && courses.length > 0" :courses="courses[0]" />
          </div>
        </div>

        <div class="footer-main__media">
          <FooterAppInfo :infos="businessInfo" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterNavigations from '@library/section/footer/footer-main/footer-navigations.vue'
import FooterCourses from '@library/section/footer/footer-main/footer-courses.vue'
import FooterAppInfo from '@library/section/footer/footer-main/footer-app-info.vue'
import methodPayment from '@design-system/section/footer/footer-main/method-payment.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SectionFooter',
  components: {
    FooterAppInfo,
    FooterCourses,
    FooterNavigations,
    methodPayment
  },
  async asyncData ({ store }) {
    await store.dispatch('global/asyncFooterData')
  },
  data () {
    return {
      navigationList: [],
      courses: [],
      businessInfo: []
    }
  },
  computed: {
    ...mapGetters('global', ['getFooter']),

    pageHasFixedButton () {
      const locale = `___${this.$i18n.locale}`
      const routeName = this.$route.name.replace(locale, '')
      const pagesHas = ['tutor-name']
      return pagesHas.includes(routeName)
    }
  },
  mounted () {
    this.courses = this.getFooter?.links?.filter(obj => obj.hasOwnProperty('children'))
    this.navigationList = this.getFooter?.links?.filter(obj => !obj.hasOwnProperty('children'))
    this.businessInfo = this.getFooter?.business_info
  },
  methods: {
    privacyClicked (url) {
      console.log('privacy clicked', url)
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  @apply mb-4 lg:mb-6;
  &--fixed-button {
    @apply mb-28 lg:mb-4;
  }
}
.footer-main {
  @apply bg-body-2 w-full py-8 px-4 lg:p-10;
  &__container {
    @apply container p-0 flex flex-col lg:flex-row   flex-wrap lg:flex-nowrap;
  }
  &__navigations {
  }
  &__languages {
  }
  &__courses {
    @apply lg:mt-4;
  }
  &__media {
    @apply mt-4 lg:mt-0 lg:w-1/2 lg:flex lg:justify-end;
  }
}
.footer-sub {
  @apply w-fit flex mx-auto border-t-[1px] border-primary-100 pt-2 space-x-[3px]
    lg:text__body-1-md--regular text__caption-xs--regular text-body-1;
  &__item {
    @apply cursor-pointer hover:bg-primary-50;
  }
}
</style>
