import { render, staticRenderFns } from "./method-payment.vue?vue&type=template&id=793f0320&scoped=true&"
import script from "./method-payment.vue?vue&type=script&lang=js&"
export * from "./method-payment.vue?vue&type=script&lang=js&"
import style0 from "./method-payment.vue?vue&type=style&index=0&id=793f0320&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "793f0320",
  null
  
)

export default component.exports