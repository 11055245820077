<template>
  <div class="space-y-6">
    <component
      :is="item.href ? 'a' : 'span'"
      v-for="(item, i) in navigationList"
      v-if="item.meta.show"
      :key="i"
      class="flex items-center space-x-2 cursor-pointer"
      :href="item.href"
    >
      <BaseIcon
        v-if="item.meta && item.meta.type === 'custom'"
        custom-size="w-6 h-6"
        type="custom"
        :color="isActive(item) ? 'text-primary' : 'text-label-text'"
        :name="item.icon"
      />
      <BaseIcon
        v-else
        :name="item.icon"
        :color="isActive(item) ? 'primary' : 'label-text'"
        size="md"
      />
      <span
        class="text__headline-5-xs--regular  text-body-1"
        :class="isActive(item) ? 'text-primary' : 'text-label-text'"
      >{{ item.name }}</span>
    </component>
  </div>
</template>

<script>
export default {
  name: 'SidebarNavigations',
  props: {
    navigationList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    isActive (item) {
      return item.href === this.$router.currentRoute.fullPath
    }
  }
}
</script>

<style scoped>

</style>
