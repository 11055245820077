<template>
  <picture>
    <source
      :data-srcset="dataSrc"
      media="(min-width: 1280px)"
    >
    <source
      :data-srcset="dataSrcMobile"
    >
    <img
      fetchpriority="high"
      :src="dataSrc"
      :alt="alt"
      :data-src="dataSrc"
      :width="width"
      :height="height"
      :class="[{'lazyload': lazy}]"
    >
  </picture>
</template>

<script>
import { isArray } from 'lodash'
export default {
  name: 'BaseImg',
  props: {
    src: {
      type: [String, Array],
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    lazy: {
      type: Boolean,
      default: true
    },
    preload: {
      type: Boolean,
      default: false
    },
    sizes: {
      type: Object,
      default: () => {
        return {
          lg: '',
          xs: ''
        }
      }
    }
  },
  head () {
    if (this.preload) {
      return {
        link: [
          {
            rel: 'prefetch',
            href: this.dataSrc
          }
        ]
      }
    }
  },
  computed: {
    dataSrc () {
      if (isArray(this.src)) {
        return this.src[1]
      } else {
        return this.src
      }
    },
    dataSrcMobile () {
      if (isArray(this.src)) {
        return this.src[0]
      } else {
        return this.src
      }
    },
    width () {
      return this.isMobile ? this.sizes.xs[0] : this.sizes.lg[0]
    },
    height () {
      return this.isMobile ? this.sizes.xs[1] : this.sizes.lg[1]
    }
  }
}
</script>

<style scoped lang="scss">

</style>
