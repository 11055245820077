<script>
export default {
  name: 'BaseSeparator',
  props: {
    classes: {
      type: String,
      default: ''
    },
    horizontal: {
      type: Boolean,
      default: true
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    separatorClasses () {
      return this.vertical
        ? !this.classes
            ? 'h-full h-[1px] border-l-[1px] border-primary-100'
            : this.classes
        : !this.classes
            ? 'w-full w-[1px] border-t-[1px] border-primary-100'
            : this.classes
    }
  }
}
</script>

<template>
  <hr :class="separatorClasses">
</template>

<style scoped lang="scss">

</style>
