<template>
  <section>
    <BaseDrawer type="menu">
      <template #activator="{toggleSidebar}">
        <BaseIcon name="menu" size="md" color="color-icon" @click.native="toggleSidebar" />
      </template>
      <template #close>
        <div class="mt-4 px-6 cursor-pointer flex items-center justify-between">
          <HeaderNavUser
            :on-desktop="false"
            @userNavClicked="$emit('userNavClicked', $event)"
            @guestNavClicked="$emit('guestNavClicked')"
          />
          <BaseIcon name="close" size="md" color="label-text" />
        </div>
        <div v-if="getMobileMenuState.menu === 'megaMenu'" class="flex mt-8 pl-4" @click="changeState($event)">
          <BaseIcon name="arrow_back" size="xs" color="color-icon" class="mr-4" />
          <span class="text__headline-5-xs--regular ">{{ getMobileMenuState.title }}</span>
        </div>
      </template>
      <template #sidebar>
        <SidebarContent
          :navigation-list="navigationListMobile"
          @navigationClicked="$emit('navigationClicked', $event)"
        />
      </template>
    </BaseDrawer>
  </section>
</template>

<script>

import SidebarContent from '@design-system/section/sidebar/sidebar-content/sidebar-content.vue'
import { mapActions, mapGetters } from 'vuex'
import HeaderNavUser from '@design-system/section/header/header-shared/header-nav-user.vue'

export default {
  name: 'SectionSidebar',
  components: { SidebarContent, HeaderNavUser },
  inject: {
    // provide from header.vue
    navigationListMobile: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      showTitle: false,
      title: ''
    }
  },
  computed: {
    ...mapGetters('global', ['getMobileMenuState', 'getMegaMenu'])
  },
  methods: {
    ...mapActions('global', ['changeMobileMenu']),
    changeState (e) {
      e.stopPropagation()
      if (this.getMobileMenuState.layout === 'children') {
        const state = {
          menu: 'megaMenu',
          layout: 'firstLevel',
          title: this.getMegaMenu.anchor
        }
        this.changeMobileMenu(state)
      } else {
        const state = {
          menu: 'default',
          layout: '',
          title: ''
        }
        this.changeMobileMenu(state)
      }
    }
  }

}
</script>

<style scoped>

</style>
