<template>
  <span class="rectangle" :class="[ `bg-${color}` ]"></span>
</template>

<script>
export default {
  name: 'BaseRectangle',
  props: {
    color: {
      type: String,
      default: 'black'
    }
  }
}
</script>

<style lang="scss" scoped>
.rectangle {
    @apply w-3 h-3 lg:w-4 lg:h-4 inline-block;
}
</style>
