<template>
  <div class="expansion-div">
    <div
      :class="height"
      class="expansion-div__content"
    >
      <slot name="content" />
    </div>
    <slot name="action">
      <div v-if="showAction" :class="justify" class="expansion-div__action" @click="toggle">
        <BaseIcon v-if="actionType !== 'outline'" size="xs" class="transition" name="arrow_drop_down_circle" :class="{'rotate-180': !isClosed}" />
        <BaseButton
          :label="label"
          color="primary"
          :flat="actionType === 'flat'"
          :outline="actionType === 'outline'"
          :light="actionType === 'outline'"
          :size="actionSize"
          class="font-normal"
        />
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'ExpansionDiv',
  props: {
    heightDefault: {
      type: String,
      default: 'max-h-12'
    },
    showAction: {
      type: Boolean,
      default: true
    },
    actionSize: {
      type: String,
      default: 'md'
    },
    actionType: {
      type: String,
      default: 'flat'
    },
    justifyBtn: {
      type: String,
      default: 'center'
    }
  },
  data () {
    return {
      height: 'max-h-12'
    }
  },
  computed: {
    isClosed () {
      return this.height === this.heightDefault
    },
    label () {
      return this.isClosed ? 'Show more' : 'Show less'
    },
    justify () {
      return `justify-${this.justifyBtn}`
    }
  },
  mounted () {
    this.height = this.heightDefault
  },
  methods: {
    toggle () {
      this.height = this.height === this.heightDefault ? 'max-h-[20000px]' : this.heightDefault
    }
  }

}
</script>

<style scoped lang="scss">
.expansion-div {
  &__content {
    @apply transition-all delay-300 overflow-hidden relative;
   }
  &__action {
    @apply flex items-center space-x-1 mt-6 cursor-pointer;
  }
}
.font-normal {
  font-weight: normal;
}
</style>
