<template>
  <component
    :is="tag"
    class="base-button px-4 flex"
    :class="[`base-button--${color}`, `base-button--${size}`, {outline}, {flat}, {underline},
             {'base-button--dense': dense} ,
             {'base-button--light': light},
             classes,buttonBG, {'pointer-events-none': loading}]"
    v-bind="attr"
    v-on="$listeners"
  >
    <slot v-if="!loading">
      <BaseIcon
        v-if="iconLeft"
        :name="iconLeft"
        :size="iconLeftSize || size"
        class="mr-2"
        :color="iconColor"
      />
      <BaseIcon
        v-if="iconLeftCustom"
        :name="iconLeftCustom"
        custom-size="w-[24px] h-[24px]"
        type="custom"
        class="mr-2"
        :color="iconColor"
      />
      <span>{{ label }}</span>
    </slot>
    <span v-else class="flex space-x-2">
      <svg
        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        :class="loadingColor[0]"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-0"
          cx="12"
          cy="12"
          r="10"
          fill="currentColor"
          stroke-width="4"
        />
        <path
          class="opacity-75"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
      <span v-if="showLoadingText" class="opacity-75">{{ $t('common.labels.loading') }}</span>
    </span>
    <span v-if="badge" class="base-button__badge">
      <span>{{ badge }}</span>
    </span>
  </component>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    label: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary',
      validate: ['primary', 'error', 'light']
    },
    size: {
      type: String,
      default: 'sm-lg',
      validate: ['lg', 'md', 'sm', 'xs', 'sm-lg', 'xs-sm', 'xs-md', 'sm-md', 'xs-lg', 'md-lg', 'lg-sm']
    },
    outline: {
      type: Boolean,
      default: false
    },
    underline: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    showLoadingText: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'button',
      validate: ['button', 'a', 'nuxtLink']
    },
    to: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: ''
    },
    iconLeft: {
      type: String,
      default: ''
    },
    iconLeftSize: {
      type: String,
      default: ''
    },
    iconLeftCustom: {
      type: String,
      default: ''
    },
    buttonBG: {
      type: String,
      default: 'bg-transparent',
      validate: ['bg-transparent', 'bg-white']
    },
    badge: {
      type: Number,
      default: 0
    }
  },
  computed: {
    loadingColor () {
      return this.outline || this.flat ? ['fill-primary', 'stroke-primary'] : ['fill-white', 'stroke-current']
    },
    attr () {
      if (this.tag === 'nuxt-link') {
        return { to: this.to }
      }
      if (this.tag === 'a') {
        return { href: this.to } // TODO check this options in btn tag a , target: '_blank'
      }
      return ''
    },
    iconColor () {
      if (this.color === 'error') {
        return this.outline || this.flat ? 'error' : 'white'
      }
      return this.outline || this.flat ? 'primary' : 'white'
    }
  }
}
</script>

<style scoped lang="scss">
.base-button {
  @import "button-mixins";
  @apply relative bg-primary text-white rounded-lg transition items-center justify-center;
  $colors: primary primary-100 primary-600 "white",
  primary-50 primary-50 primary-100 primary,
  error error-100 error-600 "white",
  warning warning-100 warning-600 "white",
  success success-100 success-600 "white",
  title title title title;
  $sizes: lg lg 12 12,
  md md 11 11,
  sm sm 10 10,
  xs xs 7 7,
  xs lg 7 12,
  sm lg 10 12,
  lg sm 12 10,
  md lg 11 12,
  xs md 7 11,
  xs sm 7 10,
  sm md 10 11,
  xs sm 7 10,
  md lg 11 12;
  @include color($colors);
  @include size($sizes);

  &[disabled=disabled] {
    @apply pointer-events-none opacity-50 cursor-not-allowed;
  }

  &--light {
    @apply font-normal;
  }
  &__badge {
    @apply absolute -top-2 -right-2 rounded-full w-6 h-6 bg-pink
    text-white flex justify-center items-center text__caption-xs--bold;
  }
  &--dense {
    @apply h-auto;
  }
}
.text-shadow-button {
  text-shadow: 0px 4px 16px rgba(52, 122, 246, 0.3);
}
</style>
