import { urlParams } from '@helper/utils'
import {
  HOME, FAQ, AboutUs, ContactUs, Policy, Terms,
  TUTOR_LIST_FETCH, TUTOR_LIST_PRICE_CHART_FETCH, TUTOR_PROFILE, WORLD, TUTOR_VIDEOS,
  HOW_IT_WORKS
} from '@endpoints'
import {
  STORE_COURSE_GROUP,
  STORE_STATISTIC,
  STORE_IMPORTANT_CITIES,
  STORE_TESTIMONIAL,
  STORE_BEST_TEACHERS,
  UPDATE_LIST_QUERIES,
  STORE_LIST_PRICE_CHART,
  STORE_LIST_TEACHERS,
  STORE_META,
  STORE_PAGINATION,
  STORE_TUTOR_SELECTED,
  STORE_TUTOR_RESUME,
  STORE_TUTOR_COURSE_GROUP,
  STORE_TUTOR_COURSES,
  STORE_TUTOR_FEEDBACKS,
  STORE_FAQS,
  UPDATE_LIST_PARAMS,
  STORE_LIST_FILTERS,
  STORE_LIST_SORTS,
  STORE_INTERNAL_LINKS, STORE_BREADCRUMB, STORE_SEO_CONTENT,
  RESET_LIST_QUERIES, STORE_SHOW_TOTAL, STORE_SHOW_SORTS,
  STORE_COURSES, STORE_SHOW_TEACHERS_COUNT,
  RESET_LIST_PARAMS, STORE_WORLD_COUNTRIES, PUSH_LIST_TEACHERS,
  STORE_LIST_DESIGN_COLOR, STORE_LIST_TITLE, STORE_FOOTER, STORE_HOME_START_TEACHING, STORE_BLOG_POSTS,
  STORE_HOME_JOIN_US
} from '../mutation-types'
import { UPDATE_COURSE_ID, UPDATE_COURSES_TYPE } from '../course/mutation-types'
// home page
export async function asyncDataHome ({ commit }) {
  try {
    const response = await this.$api.get({
      errorType: 'page',
      url: HOME.url,
      useCache: HOME.cache
    })
    const data = response?.data
    if (data) {
      commit(STORE_COURSE_GROUP, data?.course_groups)
      commit(STORE_IMPORTANT_CITIES, data?.important_cities)
      commit(STORE_SHOW_TEACHERS_COUNT, data?.show_teachers_count)
      commit(STORE_HOME_START_TEACHING, data?.start_teaching)
      commit(STORE_HOME_JOIN_US, data?.join_us)
      commit('global/' + STORE_BEST_TEACHERS, data?.best_teachers, { root: true })
      commit('global/' + STORE_META, data?.meta, { root: true })
      commit('global/' + STORE_STATISTIC, data?.statistics, { root: true })
      commit('global/' + STORE_TESTIMONIAL, data?.testimonials, { root: true })
      commit('global/' + STORE_FAQS, data?.faq, { root: true })
      commit('global/' + STORE_BREADCRUMB, data?.breadcrumb, { root: true })
      commit('global/' + STORE_SEO_CONTENT, data?.content, { root: true })
      commit('global/' + STORE_INTERNAL_LINKS, data?.internal_links, { root: true })
      commit('global/' + STORE_BLOG_POSTS, data?.blog_posts, { root: true })
    }
  } catch (e) {
    console.log(e)
  }
}

// tutor list page
export async function asyncDataTutorList ({ commit, state }) {
  try {
    const response = await this.$api.get({
      errorType: 'page',
      url: TUTOR_LIST_FETCH.url,
      query: { ...state.listQueries, parameters: Object.values(state.listParams) },
      useCache: TUTOR_LIST_FETCH.cache
    })
    const data = response?.data
    if (data) {
      commit('teacher/' + STORE_LIST_TEACHERS, data?.teachers, { root: true })
      commit(STORE_LIST_DESIGN_COLOR, data?.design_color)
      commit(STORE_LIST_FILTERS, data?.filters)
      commit(STORE_LIST_SORTS, data?.sorts)
      commit(STORE_LIST_TITLE, data?.list_title)
      commit(STORE_SHOW_SORTS, data?.show_sorts)
      commit(STORE_SHOW_TOTAL, data?.pagination.show_total)
      if (data?.filters?.course) {
        commit('course/' + UPDATE_COURSE_ID, data?.filters?.course?.id, { root: true })
        const type = data?.filters?.course?.type === 'COURSE' ? 'SIBLINGS' : 'CHILDREN'
        commit('course/' + UPDATE_COURSES_TYPE, type, { root: true })
      }
      commit('global/' + STORE_PAGINATION, data?.pagination, { root: true })
      commit('global/' + STORE_FAQS, data?.faq, { root: true })
      commit('global/' + STORE_META, data?.meta, { root: true })
      commit('global/' + STORE_TESTIMONIAL, data?.testimonials, { root: true })
      commit('global/' + STORE_BREADCRUMB, data?.breadcrumb, { root: true })
      commit('global/' + STORE_SEO_CONTENT, data?.content, { root: true })
      commit('global/' + STORE_INTERNAL_LINKS, data?.internal_links, { root: true })
    }
  } catch (e) {
  }
}

// tutor profile page
export async function asyncDataTutorProfile ({ commit, state }, payload) {
  try {
    const response = await this.$api.get({
      errorType: 'page',
      url: urlParams(TUTOR_PROFILE.url, { username: payload.username }),
      useCache: TUTOR_PROFILE.cache
    })
    const data = response?.data
    if (data) {
      data.feedbacks.teacherId = data?.teacher?.id
      commit('course/' + STORE_COURSES, data?.all_course_groups, { root: true })
      commit('teacher/' + STORE_TUTOR_COURSES, data?.course_groups?.courses, { root: true })
      commit('teacher/' + STORE_TUTOR_COURSE_GROUP, data?.course_groups?.course_groups, { root: true })
      commit('teacher/' + STORE_TUTOR_RESUME, data?.resume_items, { root: true })
      commit('teacher/' + STORE_TUTOR_SELECTED, data?.teacher, { root: true })
      commit('teacher/' + STORE_TUTOR_FEEDBACKS, data?.feedbacks, { root: true })
      commit('global/' + STORE_META, data?.meta, { root: true })
      commit('global/' + STORE_BREADCRUMB, data?.breadcrumb, { root: true })
    }
  } catch (e) {
  }
}
export async function asyncDataAboutUs ({ commit }) {
  try {
    const response = await this.$api.get({
      url: AboutUs.url,
      errorTypeCheck: 'ignore-500',
      useCache: AboutUs.cache
    })
    const data = response?.data
    if (data) {
      commit('global/' + STORE_META, data?.meta, { root: true })
      commit('global/' + STORE_BREADCRUMB, data?.breadcrumb, { root: true })
    }
  } catch (e) {
  }
}
export async function asyncDataContactUs ({ commit }) {
  try {
    const response = await this.$api.get({
      url: ContactUs.url,
      errorTypeCheck: 'ignore-500',
      useCache: ContactUs.cache
    })
    const data = response?.data
    if (data) {
      commit('global/' + STORE_META, data?.meta, { root: true })
      commit('global/' + STORE_BREADCRUMB, data?.breadcrumb, { root: true })
    }
  } catch (e) {
  }
}
export async function asyncDataPolicy ({ commit }) {
  try {
    const response = await this.$api.get({
      url: Policy.url,
      errorTypeCheck: 'ignore-500',
      useCache: Policy.cache
    })
    const data = response?.data
    if (data) {
      commit('global/' + STORE_META, data?.meta, { root: true })
      commit('global/' + STORE_BREADCRUMB, data?.breadcrumb, { root: true })
    }
  } catch (e) {
  }
}
export async function asyncDataTerms ({ commit }) {
  try {
    const response = await this.$api.get({
      url: Terms.url,
      errorTypeCheck: 'ignore-500',
      useCache: Terms.cache
    })
    const data = response?.data
    if (data) {
      commit('global/' + STORE_META, data?.meta, { root: true })
      commit('global/' + STORE_BREADCRUMB, data?.breadcrumb, { root: true })
    }
  } catch (e) {
  }
}
export async function asyncDataWorld ({ commit }) {
  try {
    const response = await this.$api.get({
      url: WORLD.url,
      errorTypeCheck: 'ignore-500',
      useCache: WORLD.cache
    })
    const data = response?.data
    if (data) {
      commit(STORE_WORLD_COUNTRIES, data?.countries)
      commit('global/' + STORE_META, data?.meta, { root: true })
      commit('global/' + STORE_BREADCRUMB, data?.breadcrumb, { root: true })
    }
  } catch (e) {
  }
}
export async function fetchListPriceChart ({ commit, state }) {
  try {
    const response = await this.$api.get({
      url: TUTOR_LIST_PRICE_CHART_FETCH.url,
      query: { ...state.listQueries, parameters: Object.values(state.listParams) },
      errorTypeCheck: 'ignore-500',
      useCache: TUTOR_LIST_PRICE_CHART_FETCH.cache
    })
    const data = response?.data
    if (data) {
      commit(STORE_LIST_PRICE_CHART, data)
    }
  } catch (e) {
  }
}
export async function asyncDataFaq ({ commit }) {
  try {
    const response = await this.$api.get({
      errorType: 'page',
      url: FAQ.url,
      useCache: FAQ.cache
    })
    const data = response?.data
    if (data) {
      commit('global/' + STORE_FAQS, data?.faq, { root: true })
      commit('global/' + STORE_META, data?.meta, { root: true })
      commit('global/' + STORE_BREADCRUMB, data?.breadcrumb, { root: true })
    }
  } catch (e) {
    console.log(e)
  }
}
export function updateListQueries ({ commit }, payload) {
  commit(UPDATE_LIST_QUERIES, payload)
}
export function updateListParams ({ commit }, payload) {
  commit(UPDATE_LIST_PARAMS, payload)
}
export function storeListQueries ({ commit }, payload) {
  commit(RESET_LIST_QUERIES, payload)
}
export function storeListParams ({ commit }, payload) {
  commit(RESET_LIST_PARAMS, payload)
}
export async function fetchTutorList ({ state, commit }, payload) {
  try {
    const response = await this.$api.get({
      url: TUTOR_LIST_FETCH.url,
      query: { ...state.listQueries, parameters: Object.values(state.listParams) },
      useCache: TUTOR_LIST_FETCH.cache
    })
    const data = response?.data
    if (data) {
      commit('teacher/' + PUSH_LIST_TEACHERS, data?.teachers, { root: true })
      commit('global/' + STORE_PAGINATION, data?.pagination, { root: true })
      return response
    }
  } catch (e) {
  }
}
export async function fetchTutorVideo ({ state, commit }, payload) {
  try {
    const response = await this.$api.get({
      url: urlParams(TUTOR_VIDEOS.url, { teacherId: state.selectedTeacher.id }),
      useCache: TUTOR_VIDEOS.cache
    })
    const data = response?.data
    if (data) {
      // commit('teacher/' + PUSH_LIST_TEACHERS, data?.teachers, { root: true })
      // commit('global/' + STORE_PAGINATION, data?.pagination, { root: true })
      return response
    }
  } catch (e) {
  }
}
export async function asyncDataHowItWorks ({ commit }) {
  try {
    const response = await this.$api.get({
      errorType: 'page',
      url: HOW_IT_WORKS.url,
      useCache: HOW_IT_WORKS.cache
    })
    const data = response?.data
    if (data) {
      commit('global/' + STORE_META, data?.meta, { root: true })
      commit('global/' + STORE_BREADCRUMB, data?.breadcrumb, { root: true })
    }
  } catch (e) {
    console.log(e)
  }
}
