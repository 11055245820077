/** @module Router/list */

/**
 * list of name for better redirect routing
 * */
// public route
export const home = { name: 'index', path: '/' }
export const tutor_list = { name: 'tutor-list', path: '/tutor-list' }
export const tutor_list_online_english = { name: 'tutor-list-online-english', path: '/tutor-list/online/english' }
export const tutor_profile = { name: 'tutor-name', path: '/tutor' }
export const tutor_request = { name: 'tutor-request', path: '/tutor-request' }
export const faq = { name: 'faq', path: '/faq' }
export const world = { name: 'world', path: '/world' }
export const privacy_policy = { name: 'privacy-policy', path: '/privacy-policy' }
export const terms = { name: 'terms', path: '/terms' }
export const about_us = { name: 'about-us', path: '/about-us' }
export const contact_us = { name: 'contact-us', path: '/contact-us' }
export const mag = { name: 'mag', path: '/mag/' }
export const tutor_request_course = { name: 'tutor-request-course', path: '/tutor-request/course' }
export const tutor_request_price = { name: 'tutor-request-price-id', path: '/tutor-request/price/' }
export const tutor_request_languages = { name: 'tutor-request-languages-id', path: '/tutor-request/languages/' }
export const tutor_request_calendar = { name: 'tutor-request-calendar-id', path: '/tutor-request/calendar/' }
export const tutor_request_description = { name: 'tutor-request-description-id', path: '/tutor-request/description/' }
export const tutor_request_tracking = { name: 'tutor-request-tracking-id', path: '/tutor-request/tracking/' }
export const tutor_request_confirm = { name: 'tutor-request-confirm-id', path: '/tutor-request/confirm/' }
export const tutor_request_form = { name: 'tutor-request-form', path: '/tutor-request/form' }
export const tutor_request_finish = { name: 'tutor-request-finish-id', path: '/tutor-request/finish/' }
export const how_it_works = { name: 'How it works', path: '/how-it-works' }

// start teaching
export const start_teaching = { name: 'start-teaching', path: '/start-teaching' }
export const start_teaching_personal_info = { name: 'start-teaching-personal-info', path: '/start-teaching/personal-info' }
export const start_teaching_email_registered = { name: 'start-teaching-email-registered', path: '/start-teaching/email-registered' }
export const start_teaching_steps_tracking = { name: 'start-teaching-steps-tracking', path: '/start-teaching/steps/tracking' }
export const start_teaching_ticket_list = { name: 'start-teaching-ticket-list', path: '/start-teaching/ticket/list' }
export const start_teaching_ticket_view = { name: 'start-teaching-ticket-view-id', path: '/start-teaching/ticket/view/' }
export const start_teaching_ticket_new = { name: 'start-teaching-ticket-new', path: '/start-teaching/ticket/new' }
// auth
export const login = { name: 'auth-login', path: '/auth/login' }
export const login_by_oauth = { name: 'auth-login-by-oauth', path: 'auth/login-by-oauth' }
export const logout = { name: 'auth-logout', path: '/auth/logout' }
export const signup = { name: 'auth-signup', path: '/auth/signup' }
export const auth_verify = { name: 'auth-verify', path: '/auth/verify' }
export const set_password = { name: 'auth-set-password', path: '/auth/set-password' }
export const forget_password = { name: 'auth-forget-password', path: '/auth/forget-password' }
// profile
export const profile_home = { name: 'profile', path: '/profile' }
export const profile_requests = { name: 'profile-requests', path: '/profile/requests' }
export const profile_classes = { name: 'profile-classes', path: '/profile/classes' }
export const profile_wallet = { name: 'profile-wallet', path: '/profile/wallet' }
export const invoice_callback = { name: 'profile-invoice-callback-id', path: '/profile/invoice/callback/' }
export const profile_class = { name: 'profile-class-id', path: '/profile/class/' }
export const profile_class_payment = { name: 'profile-class-id-payment', path: '/profile/class/_id/payment' }
export const profile_discount_pack_history = { name: 'profile-class-id-discount-pack-history', path: '/profile/class/_id/discount-pack-history' }
export const profile_support_list = { name: 'profile-support-list', path: '/profile/support/list' }
export const profile_support_view = { name: 'profile-support-view-id', path: '/profile/support/' }
export const profile_support_new = { name: 'profile-support-new', path: '/profile/support/new' }
export const profile_feedback = { name: 'profile-class-id-feedback', path: '/profile/class/_id/feedback' }
export const profile_invoice_callback = { name: 'profile-invoice-callback-id', path: '/profile/invoice/callback/_id' }
export const profile_invoice_payment = { name: 'profile-invoice-payment-id', path: '/profile/invoice/payment/' }
