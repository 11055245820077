<template>
  <span>
    <BaseAppLogo width="125" height="32" type="logo-full" class="lg-block-hidden" />
    <BaseAppLogo width="93" height="24" type="logo-name" class="lg-hidden-block" />
  </span>
</template>

<script>
export default {
  name: 'HeaderLogo'
}
</script>

<style scoped></style>
