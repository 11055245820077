<template>
  <div class="header-desktop">
    <div class="header-desktop__logo">
      <HeaderLogo />
    </div>
    <div class="header-desktop__navigation">
      <MegaMenu v-if="getMegaMenu && getMegaMenu.children" :menu-data="getMegaMenu" class="mr-6" />
      <HeaderDesktopNavigation
        @navigationClicked="$emit('navigationClicked', $event)"
      />
    </div>

    <div class="flex items-center">
      <BaseButton
        :label="$t('common.actions.become_a_tutor')"
        color="primary-50"
        size="sm"
        class="mr-4 w-[176px]"
        tag="a"
        :to="$links.absolute($nuxt, 'start_teaching')"
      />
      <LiveChat v-if="$auth.user" class="mr-5" />
      <HeaderNavUser
        :on-desktop="true"
        @userNavClicked="$emit('userNavClicked', $event)"
        @guestNavClicked="$emit('guestNavClicked')"
      />
    </div>
  </div>
</template>

<script>

import LiveChat from '@design-system/section/sidebar/sidebar-live-chat.vue'
import HeaderLogo from '@design-system/section/header/header-shared/header-logo.vue'
import HeaderDesktopNavigation from '@design-system/section/header/header-desktop/header-desktop-navigation.vue'
import HeaderNavUser from '@design-system/section/header/header-shared/header-nav-user.vue'
import MegaMenu from '@design-system/base/mega-menu/mega-menu.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'HeaderDesktop',
  components: {
    HeaderLogo,
    HeaderDesktopNavigation,
    MegaMenu,
    HeaderNavUser,
    LiveChat
  },
  computed: {
    ...mapGetters('global', ['getMegaMenu'])
  },
  methods: {
    toggleLanguage (lang) {
      const newLang = lang === 'fr' ? 'en' : 'fr'
      window.location.href = this.$config.baseUrl + this.switchLocalePath(newLang)
    }
  }
}
</script>

<style lang="scss" scoped>
.header-desktop {
  @apply hidden lg:flex max-w-[1200px] mx-auto justify-between items-center;
  &__logo {
    @apply flex  justify-start;
  }

  &__navigation {
    @apply flex;
  }
}
</style>
