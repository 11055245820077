import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _61211851 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _bfe55cc4 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _b96a641a = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _0414d7b0 = () => interopDefault(import('../pages/how-it-works.vue' /* webpackChunkName: "pages/how-it-works" */))
const _17894c6c = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _572f2ea4 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _4baa19a4 = () => interopDefault(import('../pages/UIKITS.vue' /* webpackChunkName: "pages/UIKITS" */))
const _2040032f = () => interopDefault(import('../pages/world.vue' /* webpackChunkName: "pages/world" */))
const _7fc2c405 = () => interopDefault(import('../pages/tutor-request/course.vue' /* webpackChunkName: "pages/tutor-request/course" */))
const _5ad8669e = () => interopDefault(import('../pages/tutor-request/form/index.vue' /* webpackChunkName: "pages/tutor-request/form/index" */))
const _2eaba2d3 = () => interopDefault(import('../pages/tutor-request/calendar/_id.vue' /* webpackChunkName: "pages/tutor-request/calendar/_id" */))
const _6ac8d771 = () => interopDefault(import('../pages/tutor-request/confirm/_id.vue' /* webpackChunkName: "pages/tutor-request/confirm/_id" */))
const _a08adca6 = () => interopDefault(import('../pages/tutor-request/description/_id.vue' /* webpackChunkName: "pages/tutor-request/description/_id" */))
const _f59388b0 = () => interopDefault(import('../pages/tutor-request/finish/_id.vue' /* webpackChunkName: "pages/tutor-request/finish/_id" */))
const _5aacda4c = () => interopDefault(import('../pages/tutor-request/languages/_id.vue' /* webpackChunkName: "pages/tutor-request/languages/_id" */))
const _d325670c = () => interopDefault(import('../pages/tutor-request/price/_id.vue' /* webpackChunkName: "pages/tutor-request/price/_id" */))
const _698c3d4c = () => interopDefault(import('../pages/tutor-request/tracking/_id.vue' /* webpackChunkName: "pages/tutor-request/tracking/_id" */))
const _d7f5daf0 = () => interopDefault(import('../pages/tutor/_name-old.vue' /* webpackChunkName: "pages/tutor/_name-old" */))
const _667c67ae = () => interopDefault(import('../pages/tutor/_name.vue' /* webpackChunkName: "pages/tutor/_name" */))
const _6f130254 = () => interopDefault(import('../dynamic-pages/tutor-list.vue' /* webpackChunkName: "" */))
const _a59d1f22 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f288b7e4 = () => interopDefault(import('../../config/pages/auth/forget-password.vue' /* webpackChunkName: "pages/auth/forget-password" */))
const _770383bc = () => interopDefault(import('../../config/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _9d72b07c = () => interopDefault(import('../../config/pages/auth/login-by-oauth.vue' /* webpackChunkName: "pages/auth/login-by-oauth" */))
const _93040772 = () => interopDefault(import('../../config/pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _b380ceba = () => interopDefault(import('../../config/pages/auth/set-password.vue' /* webpackChunkName: "pages/auth/set-password" */))
const _94723e56 = () => interopDefault(import('../../config/pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _d2b54094 = () => interopDefault(import('../../config/pages/auth/verify.vue' /* webpackChunkName: "pages/auth/verify" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en/about-us",
    component: _61211851,
    name: "about-us___en"
  }, {
    path: "/en/contact-us",
    component: _bfe55cc4,
    name: "contact-us___en"
  }, {
    path: "/en/faq",
    component: _b96a641a,
    name: "faq___en"
  }, {
    path: "/en/how-it-works",
    component: _0414d7b0,
    name: "how-it-works___en"
  }, {
    path: "/en/privacy-policy",
    component: _17894c6c,
    name: "privacy-policy___en"
  }, {
    path: "/en/terms",
    component: _572f2ea4,
    name: "terms___en"
  }, {
    path: "/en/UIKITS",
    component: _4baa19a4,
    name: "UIKITS___en"
  }, {
    path: "/en/world",
    component: _2040032f,
    name: "world___en"
  }, {
    path: "/en/tutor-request/course",
    component: _7fc2c405,
    name: "tutor-request-course___en"
  }, {
    path: "/en/tutor-request/form",
    component: _5ad8669e,
    name: "tutor-request-form___en"
  }, {
    path: "/en/tutor-request/calendar/:id?",
    component: _2eaba2d3,
    name: "tutor-request-calendar-id___en"
  }, {
    path: "/en/tutor-request/confirm/:id?",
    component: _6ac8d771,
    name: "tutor-request-confirm-id___en"
  }, {
    path: "/en/tutor-request/description/:id?",
    component: _a08adca6,
    name: "tutor-request-description-id___en"
  }, {
    path: "/en/tutor-request/finish/:id?",
    component: _f59388b0,
    name: "tutor-request-finish-id___en"
  }, {
    path: "/en/tutor-request/languages/:id?",
    component: _5aacda4c,
    name: "tutor-request-languages-id___en"
  }, {
    path: "/en/tutor-request/price/:id?",
    component: _d325670c,
    name: "tutor-request-price-id___en"
  }, {
    path: "/en/tutor-request/tracking/:id?",
    component: _698c3d4c,
    name: "tutor-request-tracking-id___en"
  }, {
    path: "/en/tutor/:name-old?",
    component: _d7f5daf0,
    name: "tutor-name-old___en"
  }, {
    path: "/en/tutor/:name?",
    component: _667c67ae,
    name: "tutor-name___en"
  }, {
    path: "/en/tutor-list/:param1?/:param2?/:param3?/:param4?/:param5?",
    component: _6f130254,
    name: "tutor-list___en"
  }, {
    path: "/",
    component: _a59d1f22,
    name: "index"
  }, {
    path: "/en/auth/forget-password",
    component: _f288b7e4,
    name: "auth-forget-password___en"
  }, {
    path: "/en/auth/login",
    component: _770383bc,
    name: "auth-login___en"
  }, {
    path: "/en/auth/login-by-oauth",
    component: _9d72b07c,
    name: "auth-login-by-oauth___en"
  }, {
    path: "/en/auth/logout",
    component: _93040772,
    name: "auth-logout___en"
  }, {
    path: "/en/auth/set-password",
    component: _b380ceba,
    name: "auth-set-password___en"
  }, {
    path: "/en/auth/signup",
    component: _94723e56,
    name: "auth-signup___en"
  }, {
    path: "/en/auth/verify",
    component: _d2b54094,
    name: "auth-verify___en"
  }, {
    path: "/fr/auth/forget-password",
    component: _f288b7e4,
    name: "auth-forget-password___fr"
  }, {
    path: "/fr/auth/login",
    component: _770383bc,
    name: "auth-login___fr"
  }, {
    path: "/fr/auth/login-by-oauth",
    component: _9d72b07c,
    name: "auth-login-by-oauth___fr"
  }, {
    path: "/fr/auth/logout",
    component: _93040772,
    name: "auth-logout___fr"
  }, {
    path: "/fr/auth/set-password",
    component: _b380ceba,
    name: "auth-set-password___fr"
  }, {
    path: "/fr/auth/signup",
    component: _94723e56,
    name: "auth-signup___fr"
  }, {
    path: "/fr/auth/verify",
    component: _d2b54094,
    name: "auth-verify___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
